//
// // Static Graphs /////////////////////////////////////////
//
// // /**
// //  * Plots a spectrogram using plotly.js
// //  * @param xAxisData Array of data to be plotted on the x axis
// //  * @param zAxisData Array of data to be plotted on the z axis as intensity. The dimension of the x axis determines the y automatically.
// //  * @param graphDivId Html element Id where the graph is to be plotted
// //  * @param colorScale Color scale for the heatmap
// //  */
// // function plotSpectrogram(xAxisData, zAxisData, graphDivId, colorScale) {
// //     let layout = {
// //         height : 200,
// //         margin: {
// //             l: 30,
// //             r: 10,
// //             b: 30,
// //             t: 0,
// //             pad: 4
// //         }
// //     };
// //     var dataPlot = [{
// //         x: xAxisData,
// //         z: utils.transpose(zAxisData),
// //         showscale: false,
// //         colorscale: colorScale,
// //         type: 'heatmap'
// //     }];
// //     Plotly.plot(graphDivId, dataPlot, layout, {
// //         displayModeBar: false
// //     });
// // }
//
// // /**
// //  * Plot for static time series
// //  * @param staticTimeSeriesTime
// //  * @param staticTimeSeriesData
// //  */
// // function plotStaticTimeSeries(staticTimeSeriesTime, staticTimeSeriesData) {
// //     // console.log(staticTimeSeriesData);
// //     var data = [
// //         {
// //             // x: ['2013-10-04 22:23:00', '2013-11-04 22:23:00', '2013-12-04 22:23:00'],
// //             y: staticTimeSeriesData,
// //             type: 'scatter'
// //         }
// //     ];
// //
// //     Plotly.newPlot('timeSeriesGraphDiv0', data);
// // }
//
// // Live: Setup containers for plots ////////////////////////////
//
// // /**
// //  * Function to setup the containers for all the live spectrogram.
// //  * @param selectedChannelArr Array of all the selected signals
// //  * @param deviceId Device id for which the signals are selected
// //  */
// // function setupSpectrogramDivs(selectedChannelArr, deviceId) {
// //     var liveSpectrogramTemplate = $('#livePlotDivTemplate').html();
// //     for(k in selectedChannelArr) {
// //         var signalInfoObject = getSignalObject(selectedChannelArr[k]);
// //         let signalRep = signalInfoObject[0].signalRepresentation;
// //         let signalName = signalInfoObject[0].signalName;
// //         // Set up context for spectrogram plots
// //         if (signalRep === "frequency") {
// //             var id = "spectrumLiveGraphDiv" + $('.spectrumLiveGraphDiv').length;
// //             $(".live-plot-body").append(liveSpectrogramTemplate);
// //             $('.spectrumLiveGraphDiv:last').attr('id', id);
// //             width = $('#spectrumLiveGraphDiv0').parent().width(); // Change the width of the plot according to the card size on the screen
// //             var background = "rgba(0, 0, 0, 1)";
// //             var target = document.getElementById(id);
// //             var canvas = document.createElement('canvas');
// //             var info = document.createElement('div');
// //             target.appendChild(info);
// //             canvas.width = width;
// //             canvas.height = height;
// //             target.appendChild(canvas);
// //             var canvasContext = canvas.getContext('2d');
// //             canvasContext.fillStyle = background;
// //             canvasContext.fillRect(0, 0, width, height);
// //             contextArray.push(canvasContext);
// //         }
// //     }
// // }
//
// // /**
// //  * Function to setup the containers for all the live time series.
// //  * @param selectedChannelArr Array of all the selected signals
// //  * @param deviceId Device id for which the signals are selected
// //  */
// // function setupTimeSeriesDivs(selectedChannelArr, deviceId) {
// //     var liveTimeSeriesTemplate = $('#liveTimeSeriesDivTemplate').html();
// //     for(k in selectedChannelArr) {
// //         var signalInfoObject = getSignalObject(selectedChannelArr[k]);
// //         let signalRep = signalInfoObject[0].signalRepresentation;
// //         let signalName = signalInfoObject[0].signalName;
// //         // Set up instances for time series plots
// //         if(signalRep === "time") {
// //             var id = "timeSeriesLiveGraphDiv" + $('.timeSeriesLiveGraphDiv').length;
// //             $(".live-plot-body").append(liveTimeSeriesTemplate);
// //             $('.timeSeriesLiveGraphDiv:last').attr('id', id);
// //             $('#' + id).addClass(deviceId + " " + signalName);
// //             width = $('#timeSeriesLiveGraphDiv0').parent().width(); // Change the width of the plot according to the card size on the screen
// //             document.getElementById(id).style.width = width + "px"; // Change the width of the plot
// //             document.getElementById(id).width = width; // Change the width of the canvas on which the plot is made
// //             // Smoothie.js stuff
// //             var dataObj = {};
// //             dataObj[signalName] = new TimeSeries();
// //             liveTimeSeriesDataArray.push(dataObj);
// //             for(i in liveTimeSeriesDataArray) {
// //                 if(Object.keys(liveTimeSeriesDataArray[i])[0] === signalName){
// //                     var chart = new SmoothieChart();
// //                     chart.addTimeSeries(liveTimeSeriesDataArray[i][signalName], { strokeStyle: '#00b5b8', fillStyle: 'rgba(0, 181, 184, 0.2)', lineWidth: 1 });
// //                     chart.streamTo(document.getElementById(id), 200);
// //                 }
// //             }
// //         }
// //     }
// // }
//
// //     // Exportable Functions ////////////////////////////
//
// //     return {
// //         setupPlotDivs : setupPlotDivs,
// //         plotStream : plotStream,
// //         plotSpectrogram : plotSpectrogram,
// //         plotStaticTimeSeries : plotStaticTimeSeries
// //     }
// // }

// import { TimeSeries, SmoothieChart } from '../javascripts/smoothie.js'

import Plotly from "plotly.js-dist";

var contextArray = [];
var contextColor = [];
let liveTimeSeriesDataArray = []; // Array of all the signal values which is plotted on the graph
let width = 2048; // Default width for the container
const height = 128; // Default height for the container

// // Live Graphs /////////////////////////////////////////

const sensorField = {
  AccelerometerX: "Vibration X-axis",
  AccelerometerY: "Vibration Y-axis",
  AccelerometerZ: "Vibration Z-axis",
  Barometer: "Ambient Pressure",
  EMI: "Electromagnetic Noise",
  GridEye: "Thermal Temperature",
  Humidity: "Ambient Humidity",
  Light: "Light Intensity",
  Magnetometer0: "Magnetic Fields X-axis",
  Magnetometer1: "Magnetic Fields Y-axis",
  Magnetometer2: "Magnetic Fields Z-axis",
  Motion: "Motion",
  Temperature: "Ambient Temperature",
  Wifi: "Wifi Signal Strength",
  Microphone: "Ambient Audio",
  Color: "Color",
};
const sensorFieldReverse = {
  "Vibration X-axis": "AccelerometerX",
  "Vibration Y-axis": "AccelerometerY",
  "Vibration Z-axis": "AccelerometerZ",
  "Ambient Pressure": "Barometer",
  "Electromagnetic Noise": "EMI",
  "Thermal Temperature": "GridEye",
  "Ambient Humidity": "Humidity",
  "Light Intensity": "Light",
  "Magnetic Fields X-axis": "Magnetometer0",
  "Magnetic Fields Y-axis": "Magnetometer1",
  "Magnetic Fields Z-axis": "Magnetometer2",
  "Motion": "Motion",
  "Ambient Temperature": "Temperature",
  "Wifi Signal Strength": "Wifi",
  "Ambient Audio": "Microphone",
  "Color": "Color",
};
const moveBy = 1;
const heatmap = [
  "05000b",
  "05000b",
  "0a000a",
  "0a000a",
  "0e000f",
  "0e000f",
  "130014",
  "130014",
  "190018",
  "190018",
  "1e001e",
  "1e001e",
  "230022",
  "230022",
  "290029",
  "290029",
  "2e002e",
  "2e002e",
  "320033",
  "320033",
  "370037",
  "370037",
  "3d003d",
  "3d003d",
  "450046",
  "450046",
  "4e014e",
  "4e014e",
  "560157",
  "560157",
  "5f005e",
  "5f005e",
  "670167",
  "670167",
  "6f0070",
  "6f0070",
  "780179",
  "780179",
  "81017b",
  "81017b",
  "870278",
  "870278",
  "8d0272",
  "8d0272",
  "94036c",
  "94036c",
  "9a0467",
  "9a0467",
  "a00560",
  "a00560",
  "a8065a",
  "a8065a",
  "ad0753",
  "ad0753",
  "b4084d",
  "b4084d",
  "bb0a47",
  "bb0a47",
  "c10a40",
  "c10a40",
  "c60b38",
  "c60b38",
  "cc0c33",
  "cc0c33",
  "d30e2c",
  "d30e2c",
  "d90d26",
  "d90d26",
  "df0f1f",
  "df0f1f",
  "e61019",
  "e61019",
  "ec1213",
  "ec1213",
  "f3120d",
  "f3120d",
  "f91306",
  "f91306",
  "fc1601",
  "fc1601",
  "fe2000",
  "fe2000",
  "ff2a01",
  "ff2a01",
  "ff3501",
  "ff3501",
  "ff3f00",
  "ff3f00",
  "ff4b01",
  "ff4b01",
  "ff5500",
  "ff5500",
  "ff5f01",
  "ff5f01",
  "ff6901",
  "ff6901",
  "ff7300",
  "ff7300",
  "ff7f00",
  "ff7f00",
  "ff8901",
  "ff8901",
  "ff9400",
  "ff9400",
  "ff9f01",
  "ff9f01",
  "ffa900",
  "ffa900",
  "ffb401",
  "ffb401",
  "ffbd02",
  "ffbd02",
  "ffc303",
  "ffc303",
  "ffc704",
  "ffc704",
  "ffcb06",
  "ffcb06",
  "ffd009",
  "ffd009",
  "ffd30a",
  "ffd30a",
  "ffd70d",
  "ffd70d",
  "ffdb0d",
  "ffdb0d",
  "ffdf10",
  "ffdf10",
  "fee310",
  "fee310",
  "ffe713",
  "ffe713",
  "ffec15",
  "ffec15",
  "ffef17",
  "ffef17",
  "fff319",
  "fff319",
  "fff61b",
  "fff61b",
  "fffb1d",
  "fffb1d",
  "feff20",
  "feff20",
  "f9fb1f",
  "f9fb1f",
  "f3f71f",
  "f3f71f",
  "eff41f",
  "eff41f",
  "eaf01f",
  "eaf01f",
  "e5ed20",
  "e5ed20",
  "dee920",
  "dee920",
  "dae720",
  "dae720",
  "d5e320",
  "d5e320",
  "cfdf20",
  "cfdf20",
  "cbdc21",
  "cbdc21",
  "c5d720",
  "c5d720",
  "c1d521",
  "c1d521",
  "bad020",
  "bad020",
  "b5cd21",
  "b5cd21",
  "afc920",
  "afc920",
  "abc621",
  "abc621",
  "a6c220",
  "a6c220",
  "a1c021",
  "a1c021",
  "9bbc20",
  "9bbc20",
  "96b823",
  "96b823",
  "93b82a",
  "93b82a",
  "91b932",
  "91b932",
  "8ebb3c",
  "8ebb3c",
  "8bbb43",
  "8bbb43",
  "8abc4c",
  "8abc4c",
  "88bd54",
  "88bd54",
  "85bd5c",
  "85bd5c",
  "83be64",
  "83be64",
  "80bf6e",
  "80bf6e",
  "7dbf75",
  "7dbf75",
  "7bc07e",
  "7bc07e",
  "7ac187",
  "7ac187",
  "78c290",
  "78c290",
  "75c298",
  "75c298",
  "72c4a1",
  "72c4a1",
  "70c5aa",
  "70c5aa",
  "6ec4b2",
  "6ec4b2",
  "6cc5ba",
  "6cc5ba",
  "6ac7c3",
  "6ac7c3",
  "6ac6c6",
  "6ac6c6",
  "72cbcd",
  "72cbcd",
  "7aced1",
  "7aced1",
  "83d1d5",
  "83d1d5",
  "8ed5d7",
  "8ed5d7",
  "97d8da",
  "97d8da",
  "a0dcdd",
  "a0dcdd",
  "a9dfe1",
  "a9dfe1",
  "b3e3e5",
  "b3e3e5",
  "bee7e9",
  "bee7e9",
  "c6e9eb",
  "c6e9eb",
  "d0eef0",
  "d0eef0",
  "d9f1f3",
  "d9f1f3",
  "e3f5f7",
  "e3f5f7",
  "edf9fa",
  "edf9fa",
  "f6fcfd",
  "f6fcfd",
  "fdffff",
  "fdffff",
];

/**
 * Function to handle times series and spectrogram data stream and forward it to appropriate plots.
 * Sample data input format:
 * {
 *     deviceId: "2d001c000d47363330353437",
 *     spectrogramData: [
 *         signalData: [fft values...],
 *         signalName: "Accelerometer-0"
 *     ],
 *     timeSeriesData: [
 *         signalData: 28,
 *         signalName: "Temperature"
 *     ]
 * }
 * @param plotData Data to plot
 */
function plotStream(plotData) {
  plotLiveTimeSeries(plotData.timeSeriesData);
  plotLiveSpectrogram(plotData.spectrogramData);
  // requestAnimationFrame(() => plotLiveSpectrogram(plotData.spectrogramData));
  plotLiveColor(plotData.colorData);
}

/**
 * Plotting the spectrogram from the live stream. This functioned is called each time a new data point is received.
 * Data Format Example: [{"signalData": [values,...], "signalName": "Microphone"},{"signalData": [values,...], "signalName": "EMI"}]
 * @param spectrogramData Data for all the spectrogram
 */
// function plotLiveSpectrogram(spectrogramData) {
//   if (spectrogramData.length !== 0) {
//     for (let j = 0; j < contextArray.length; j++) {
//       const singleFftArray = spectrogramData[j].signalData;
//       contextArray[j].drawImage(
//         contextArray[j].canvas,
//         0,
//         0,
//         width + moveBy,
//         height,
//         -moveBy,
//         0,
//         width + moveBy,
//         height
//       );
//
//       for (let i = 0; i < singleFftArray.length; i++) {
//         const mag = singleFftArray[i];
//         contextArray[j].fillStyle =
//           spectrogramData[j].signalName === "Microphone" && mag < 1
//             ? "#111111"
//             : "#" +
//               heatmap[
//                 mag * (spectrogramData[j].signalName === "Microphone" ? 6 : 3)
//               ];
//         contextArray[j].fillRect(width - moveBy, height - i, moveBy, moveBy);
//       }
//     }
//   }
// }

function plotLiveSpectrogram(spectrogramData) {
  if (spectrogramData.length !== 0) {
    const draw = () => {
      for (let j = 0; j < contextArray.length; j++) {
        const singleFftArray = spectrogramData[j].signalData;
        contextArray[j].drawImage(
          contextArray[j].canvas,
          0,
          0,
          width + moveBy,
          height,
          -moveBy,
          0,
          width + moveBy,
          height
        );

        for (let i = 0; i < singleFftArray.length; i++) {
          const mag = singleFftArray[i];
          contextArray[j].fillStyle =
            spectrogramData[j].signalName === "Microphone" && mag < 1
              ? "#111111"
              : "#" +
                heatmap[
                  mag * (spectrogramData[j].signalName === "Microphone" ? 6 : 3)
                ];
          contextArray[j].fillRect(width - moveBy, height - i, moveBy, moveBy);
        }
      }
    };

    // Use requestAnimationFrame to optimize the rendering
    requestAnimationFrame(draw);
  }
}

/**
 * Plotting the spectrogram from the live stream. This functioned is called each time a new data point is received.
 * Data Format Example: [{"signalData": [values,...], "signalName": "Microphone"},{"signalData": [values,...], "signalName": "EMI"}]
 * @param spectrogramData Data for all the spectrogram
 */
function plotLiveColor(spectrogramData) {
  // width = 1253
  if (spectrogramData.length !== 0) {
    let colorR = spectrogramData[0].signalData[0];
    let colorG = spectrogramData[0].signalData[1];
    let colorB = spectrogramData[0].signalData[2];

    for (const j in contextColor) {
      // const singleFftArray = spectrogramData[j].signalData
      const singleFftArray = [...Array(128).keys()];
      contextColor[j].drawImage(
        contextColor[j].canvas,
        0,
        0,
        width + moveBy,
        height,
        -moveBy,
        0,
        width + moveBy,
        height
      );
      for (const i in singleFftArray) {
        const mag = singleFftArray[i];
        // contextArray[j].fillStyle = 'rgba(0,120,67,1)'
        contextColor[j].fillStyle =
          "rgba(" + colorR + "," + colorG + "," + colorB + ",1)";
        contextColor[j].fillRect(width - moveBy, height - i, moveBy, moveBy);
      }
    }
  }
}

/**
 * Plot the time series in a single plot and multiple subplots
 * Data Format Example: [{"signalData": 975, "signalName": "Barometer"},{"signalData": 558, "signalName": "Light"}]
 * The above example of data will plot 2 time series. The name of the signal must be same as the one selected from the modal.
 * @param timeSeriesData Data array to plot.
 */
var plotX = 100;
function plotLiveTimeSeries(timeSeriesData) {
  console.log(timeSeriesData, "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
  if (!timeSeriesData.length) {
    return;
  }
  var graphId = [];
  var updateX = [];
  var updateY = [];

  for (var i in timeSeriesData) {
    updateX.push([plotX]);
    if (timeSeriesData[i].signalName === "Temperature") {
      updateY.push([(parseInt(timeSeriesData[i].signalData) - 6).toString()]);
    } else {
      updateY.push([timeSeriesData[i].signalData]);
    }
    graphId.push(parseInt(i));
  }

  var update = {
    x: updateX,
    y: updateY,
  };

  var minuteView = {
    xaxis: {
      type: "lines",
      range: [plotX + 100, plotX],
      zeroline: false,
      ticks: "",
      showticklabels: false,
    },
  };

  // console.log(update);
  // console.log(plotX);
  try {
    Plotly.relayout("graph", minuteView);
    console.log(update, graphId);
    Plotly.extendTraces("graph", update, graphId);
    plotX -= 1;
  } catch (error) {
    console.log("graph div is not plotted yet", error);
  }

  // for (const i in timeSeriesData) {
  //   const signalName = timeSeriesData[i].signalName
  //   console.log(timeSeriesData[i].signalData)
  //   // for (const j in liveTimeSeriesDataArray) {
  //   //   if (Object.keys(liveTimeSeriesDataArray[j])[0] === signalName) {
  //   //     liveTimeSeriesDataArray[j][signalName].append(new Date().getTime(), timeSeriesData[i].signalData)
  //   //   }
  //   // }
  // }
}

function createTimeline(signalObject) {
  const signalRangeMap = {
    Barometer: [900, 1100],
    Humidity: [10, 30],
    Light: [0, 2000],
    Magnetometer0: [0, 50],
    Magnetometer1: [-60, -10],
    Magnetometer2: [0, 60],
    Temperature: [18, 30],
    Motion: [0, 4000],
    Wifi: [-40, -80],
  };

  contextArray = [];
  console.log("createTimeline", signalObject);
  // liveTimeSeriesDataArray = []
  // for (const val in signalObject) {
  //   const id = document.getElementById('container-timeseries-' + signalObject[val])
  //   const box = document.getElementById('parent-div')
  //   width = box.offsetWidth - 10
  //   id.width = width
  //   // const chart = new SmoothieChart()
  //   // const dataObj = {}
  //   // dataObj[signalObject[val]] = new TimeSeries()
  //   // liveTimeSeriesDataArray.push(dataObj)
  //   // chart.addTimeSeries(liveTimeSeriesDataArray[val][signalObject[val]],
  //   //   { strokeStyle: '#00b5b8', fillStyle: 'rgba(0, 181, 184, 0.2)', lineWidth: 1 })
  //   // // chart.addTimeSeries(random,
  //   // //   { strokeStyle: '#00b5b8', fillStyle: 'rgba(0, 181, 184, 0.2)', lineWidth: 1  });
  //   // console.log('Signal Object', signalObject[val])
  //   // chart.streamTo(id, 200)
  // }

  // Function to get equal intervals between two numbers
  function getIntervals(range, parts) {
    let ySpace = 0.03; // Space between two subplots
    var result = [],
      length = (range[1] - range[0]) / parts,
      i = 0,
      t;

    while (i < parts) {
      t = range[0] + i * length;
      // console.log([t, t + length - ySpace])
      result.push([t, t + length - ySpace]);
      i++;
    }
    return result;
  }

  var data = [];

  // Initialize the layout
  const height =
    signalObject.length > 5 ? 100 : signalObject.length > 1 ? 200 : 300;
  const layout = {
    height: height * signalObject.length,
    annotations: [],
    xaxis: {
      zeroline: false,
      ticks: "",
      showticklabels: false,
    },
  };

  var yIntervals = getIntervals([0, 1], signalObject.length);

  for (const i in signalObject) {
    let trace = {};
    if (i > 0) {
      let yIndex = parseInt(i) + 1;
      trace = {
        x: [],
        y: [],
        type: "lines",
        yaxis: "y" + yIndex,
        name: signalObject[i],
      };
    } else {
      trace = {
        x: [],
        y: [],
        type: "lines",
        name: signalObject[i],
      };
    }
    data.push(trace);

    // Ranges for each
    console.log(signalObject[i]);

    // Define the yaxis subplot layout: Set the range for each plot
    let ySubPlotIndex = parseInt(i) + 1;
    if (i > 0) {
      // layout['yaxis' + ySubPlotIndex] = {domain: yIntervals[i], 'zeroline': false, range: signalRangeMap[signalObject[i]]}  // ToDo: Set the range for each trace
      layout["yaxis" + ySubPlotIndex] = {
        domain: yIntervals[i],
        zeroline: false,
        range: signalRangeMap[signalObject[i]],
      }; // ToDo: Set the range for each trace
    } else {
      console.log(yIntervals[i]);
      // layout['yaxis'] = {domain: yIntervals[i], 'zeroline': false, yaxis: {range: signalRangeMap[signalObject[i]]}}
      // layout['yaxis'] = {domain: yIntervals[i], 'zeroline': false}
      layout["yaxis"] = {
        domain: yIntervals[i],
        zeroline: false,
        range: signalRangeMap[signalObject[i]],
      };
    }

    // Add Annotation
    let ann = {
      text: sensorField[signalObject[i]],
      font: {
        size: 18,
        color: "#0098f0",
      },
      showarrow: false,
      x: 0.95, //position in x domain
      y: yIntervals[i][1], //position in y domain
      xref: "paper",
      yref: "paper",
      yanchor: "top",
      captureevents: true,
    };
    layout["annotations"].push(ann);
  }
  layout["showlegend"] = false;
  console.log(layout);
  console.log(data);
  Plotly.newPlot("graph", data, layout)
    .then((gd) => {
      gd.on("plotly_clickannotation", function (eventData) {
        const clickedAnnotation = sensorFieldReverse[eventData.annotation.text]; // Extract the clicked annotation text
        const mouseX = eventData.event.clientX;
        const mouseY = eventData.event.clientY;

        // Dispatch a custom event to Vue with the clicked sensor and mouse position
        const event = new CustomEvent('annotationClick', {
          detail: { sensorField: clickedAnnotation, x: mouseX, y: mouseY }
        });
        document.dispatchEvent(event);
      });
    });
}

function createSpectrogram(signalObject) {
  console.log("createSpectrogram", signalObject);
  for (const k in signalObject) {
    const id = "container-spectrogram-" + signalObject[k];
    const box = document.getElementById("parent-div");
    width = box.offsetWidth - 10;
    const background = "rgba(0, 0, 0, 1)";
    const target = document.getElementById(id);
    console.log(target);
    if (target == null) {
      return "Failed";
    }
    const canvas = document.createElement("canvas");
    const info = document.createElement("div");
    target.appendChild(info);
    canvas.width = width;
    canvas.height = height;
    target.appendChild(canvas);
    const canvasContext = canvas.getContext("2d");
    canvasContext.fillStyle = background;
    canvasContext.fillRect(0, 0, width, height);
    if (signalObject[k] === "Color") {
      contextColor.push(canvasContext);
    } else {
      contextArray.push(canvasContext);
    }
  }
  return "Success";
}

function removeTimeSeriesGraph() {
  Plotly.purge("graph");
}

// Spectrogram New Implementation
var canvases = {};

function drawCanvas(canvasId, w, h) {
  const canvas = canvases[canvasId];
  // Code to draw on the canvas goes here
  canvas.fillStyle = "red";
  canvas.fillRect(0, 0, w, h);
}
//
// function createCanvases(selected_spectrograms) {
//   console.log(selected_spectrograms)
//   selected_spectrograms.forEach((canvasObj, index) => {
//     console.log(canvasObj)
//     const id = 'canvas-' + index
//     let box = document.getElementById('parent-div')
//     width = box.offsetWidth - 10
//     let background = 'rgba(0, 0, 0, 1)'
//     let target = document.getElementById(id)
//     console.log(target)
//     if(target == null) {
//       return "Failed"
//     }
//     let canvas = document.createElement('canvas')
//     let info = document.createElement('div')
//     target.appendChild(info)
//     canvas.width = width
//     canvas.height = height
//     target.appendChild(canvas)
//     canvases[id] = canvas.getContext('2d')
//     drawCanvas(id, width, height)
//     // canvasContext.fillStyle = background
//     // canvasContext.fillRect(0, 0, width, height)
//     // if (signalObject[k] === 'Color') {
//     //   contextColor.push(canvasContext)
//     // } else {
//     //   contextArray.push(canvasContext)
//     // }
//   });
//   // this.canvasObjects.forEach((canvasObj) => {
//   //   console.log(canvasObj.id)
//   //   this.canvases[canvasObj.id] = this.$refs[`canvas-${canvasObj.id}`][0].querySelector("canvas").getContext("2d");
//   //   this.drawCanvas(canvasObj.id);
//   // });
// }

export { createTimeline, createSpectrogram, plotStream, removeTimeSeriesGraph };
