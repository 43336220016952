import crypto from "crypto";
import settings from "@/settings.js";
import apis from "@/javascripts/buildingdepot/index";

function encrypt(data) {
  const ENC_KEY = settings.cryptoKeys.ENC_KEY; // set random encryption key
  const IV = settings.cryptoKeys.IV; // set random initialisation vector
  // console.log("## Inside encryptUser data", ENC_KEY, IV);
  let cipher = crypto.createCipheriv("aes-256-cbc", ENC_KEY, IV);
  let encrypted = cipher.update(JSON.stringify(data), "utf8", "base64");
  encrypted += cipher.final("base64");

  // console.log("Inside encryptUser data", encrypted);
  return encrypted;
}

function decrypt(encrypted) {
  let decipher = crypto.createDecipheriv(
    "aes-256-cbc",
    settings.cryptoKeys.ENC_KEY,
    settings.cryptoKeys.IV
  );
  let decrypted = decipher.update(encrypted, "base64", "utf8");
  // console.log("decrypt data", decrypted);
  return decrypted + decipher.final("utf8");
}

async function readCookie(key, cookiesRef) {
  let cookieObj = cookiesRef.get(key);
  // Check login condition
  if (cookieObj === null) {
    // Check if session cookie exists
    let sessionCookie = cookiesRef.get("session");
    if (sessionCookie === null) {
      return Promise.resolve({
        success: false,
        error: "No user info. Please login!",
      });
    } else {
      // Setup a new user cookie
      const {email, client_id, client_key} = JSON.parse(decrypt(sessionCookie));
      let apiSuccess = await apis.oAuth.getAccessTokenFromClientAPI(client_id, client_key);
      if (apiSuccess.success === "True") {
        // Set Cookie
        await apis.oAuth.writeCookieAPI(
            "user",
            {
              email: email,
              bd_access_token: apiSuccess.access_token,
            },
            cookiesRef,
            "1d"
        );
      } else {
        return Promise.resolve({
          success: false,
          error: "Oops! Your session has expired. Please log in again to continue.",
        });
      }
      cookieObj = cookiesRef.get(key); // Get the new cookie
    }
  }

  try {
    return Promise.resolve({
      success: true,
      data: JSON.parse(decrypt(cookieObj)),
    });
  } catch (e) {
    console.log("Cookie expired. Please login!", e);
    return Promise.resolve({
      success: false,
      error:
          "Oops! Your session has expired. Please log in again to continue.",
    });
  }
}


function writeCookie(key, value, cookiesRef, expires) {
  // console.log("Write Cookie function");

  let encryptValue = encrypt(value);
  console.log("encryptValue", encryptValue);
  // cookiesRef.set(key, encryptValue);

  if (cookiesRef.set(key, encryptValue, expires)) {
    // console.log("Write Access Token Cookie");
    return Promise.resolve({
      success: true,
    });
  } else {
    console.log("write cookies false");

    return Promise.resolve({
      success: false,
      error:
        "We're having trouble setting cookies. To log in successfully, " +
        "please ensure your browser allows cookie access for the website.",
    });
  }
}

export { encrypt, decrypt, writeCookie, readCookie };
