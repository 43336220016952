<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#dashboard">{{ routeName }}</a>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <!--      <div class="search-bar input-group" @click="searchModalVisible = true">-->
      <!--        <button-->
      <!--          class="btn btn-link"-->
      <!--          id="search-button"-->
      <!--          data-toggle="modal"-->
      <!--          data-target="#searchModal"-->
      <!--        >-->
      <!--          <i class="tim-icons icon-zoom-split"></i>-->
      <!--        </button>-->
      <!--        &lt;!&ndash; You can choose types of search input &ndash;&gt;-->
      <!--      </div>-->
      <!--      <modal-->
      <!--        :show.sync="searchModalVisible"-->
      <!--        class="modal-search"-->
      <!--        id="searchModal"-->
      <!--        :centered="false"-->
      <!--        :show-close="true"-->
      <!--      >-->
      <!--        <input-->
      <!--          slot="header"-->
      <!--          v-model="searchQuery"-->
      <!--          type="text"-->
      <!--          class="form-control"-->
      <!--          id="inlineFormInputGroup"-->
      <!--          placeholder="SEARCH"-->
      <!--        />-->
      <!--      </modal>-->

      <div class="search-bar input-group" @click="OnboardModalVisible = true">
        <!--
                <input type="text" class="form-control" placeholder="Search...">
                <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div>
              -->
        <button
          round
          type="primary"
          class="search-bar input-group btn btn-link"
          id="onboard-button"
          data-toggle="modal"
          data-target="#searchModal"
        >
          <i class="tim-icons el-icon-question"></i>
        </button>
        <!-- You can choose types of search input -->
      </div>
      <div class="search-bar input-group">
        <a class="navbar-brand" href="#">{{ userEmail }}</a>
      </div>

      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item"
      >
        <template slot="title">
          <!--          <div class="notification d-none d-lg-block d-xl-block"></div>-->
          <div class="d-none d-lg-block d-xl-block"></div>
          <i class="tim-icons icon-sound-wave"></i>
          <p class="d-lg-none">New Notifications</p>
        </template>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">No new notifications</a>
        </li>
        <!--        <li class="nav-link">-->
        <!--          <a href="#" class="nav-item dropdown-item">Another one</a>-->
        <!--        </li>-->
      </base-dropdown>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template slot="title">
          <div class="photo"><img src="img/placeholder.jpg" alt="" /></div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">User settings</p>
        </template>
        <!--        <li class="nav-link">-->
        <!--          <a href="#/pages/user" class="nav-item dropdown-item">Profile</a>-->
        <!--        </li>-->
        <li class="nav-link">
          <a href="#/pages/timeline" class="nav-item dropdown-item">History</a>
        </li>
        <li class="nav-link">
          <a href="#/pages/settings" class="nav-item dropdown-item">Settings</a>
        </li>
        <!--        <li class="nav-link">-->
        <!--          <a href="http://localhost:8081/" class="nav-item dropdown-item"-->
        <!--            >Search Nearby devices</a-->
        <!--          >-->
        <!--        </li>-->
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item" @click="logout()"
            >Log out</a
          >
        </li>
      </base-dropdown>
    </ul>
    <!-- Modal for Mites details -->
    <modal
      :show.sync="OnboardModalVisible"
      id="onboard-modal"
      body-classes="p-0"
      modal-classes="modal-dialog-centered"
    >
      <card
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <!--          <div>-->
        <VueSlickCarousel :arrows="true" :dots="true" :infinite="false">
          <card class="card-user">
            <p class="card-text"></p>
            <div class="author">
              <div class="block block-one"></div>
              <div class="block block-two"></div>
              <div class="block block-three"></div>
              <div class="block block-four"></div>
              <a href="javascript:void(0)">
                <img class="avatar" src="img/mites.jpeg" alt="..." />
                <h5 class="title">Mites</h5>
              </a>
              <p class="description">What are the Mites Devices??</p>
            </div>
            <p></p>
            <p class="card-description text-center">
              Mites is a ubiquitous sensing device with 9 different sensors that
              capture twelve unique sensor dimensions. The sensed data is used
              to support various smart building applications such conference
              room availability or activity recognition
            </p>
            <p class="card-description text-center">
              We have carefully designed and developed the Mites devices with
              several privacy and security safeguards built-in. Our firmware
              featurizes data on-board the Mites device and never send the raw
              data. Not only does this reduce network overhead, but it also
              denatures the data, better protecting privacy restricting the
              reconstruction of the original data. In addition, none of data
              collected is personally identifiable. Privacy matters to us!
            </p>
          </card>
          <card class="card-user">
            <p class="card-text"></p>
            <div class="author">
              <div class="block block-one"></div>
              <div class="block block-two"></div>
              <div class="block block-three"></div>
              <div class="block block-four"></div>
              <a href="javascript:void(0)">
                <img class="avatar" src="img/mites.jpeg" alt="..." />
                <h5 class="title">Mites</h5>
              </a>
              <p class="description">What Data Do We Collect?</p>
            </div>
            <p></p>
            <h5 class="card-category">From your mobile device, we collect:</h5>
            <h4 class="card-title">
              <i class="tim-icons icon-shape-star text-danger"></i>
              Manufacturer
            </h4>
            <h4 class="card-title">
              <i class="tim-icons icon-mobile text-danger"></i> Phone
            </h4>
            <h4 class="card-title">
              <i class="tim-icons icon-settings-gear-63 text-danger"></i> OS
              Version
            </h4>
            <h5 class="card-category">From the Mite, we collect:</h5>
            <h4 class="card-title">
              <img
                class="img-icon"
                src="img/sensor_icons/temperature-sensor.png"
              />
              &nbsp; Ambient Temperature
            </h4>

            <h4 class="card-title">
              <img
                class="img-icon"
                src="img/sensor_icons/humidity-sensor.png"
              />
              &nbsp; Ambient Humidity
            </h4>

            <h4 class="card-title">
              <img
                class="img-icon"
                src="img/sensor_icons/barometer-sensor.png"
              />
              &nbsp; Ambient Pressure
            </h4>

            <h4 class="card-title">
              <img
                class="img-icon"
                src="img/sensor_icons/accelerometer-sensor.png"
              />
              &nbsp; Vibration
            </h4>

            <h4 class="card-title">
              <img
                class="img-icon"
                src="img/sensor_icons/microphone-sensor.png"
              />
              &nbsp; Ambient Audio
            </h4>

            <h4 class="card-title">
              <img class="img-icon" src="img/sensor_icons/color-sensor.png" />
              &nbsp; Color
            </h4>

            <h4 class="card-title">
              <img class="img-icon" src="img/sensor_icons/emi-sensor.png" />
              &nbsp; Electromagnetic Noise
            </h4>

            <h4 class="card-title">
              <img class="img-icon" src="img/sensor_icons/thermal-sensor.png" />
              &nbsp; Thermal Temperature
            </h4>

            <h4 class="card-title">
              <img class="img-icon" src="img/sensor_icons/light-sensor.png" />
              &nbsp; Light Intensity
            </h4>

            <h4 class="card-title">
              <img
                class="img-icon"
                src="img/sensor_icons/magnetometer-sensor.png"
              />
              &nbsp; Ambient Magnetic Fields
            </h4>

            <h4 class="card-title">
              <img class="img-icon" src="img/sensor_icons/motion-sensor.png" />
              &nbsp; Motion
            </h4>

            <h4 class="card-title">
              <img class="img-icon" src="img/sensor_icons/wifi-sensor.png" />
              &nbsp; Wifi RSSI
            </h4>
          </card>
          <card class="card-user">
            <p class="card-text"></p>
            <div class="author">
              <div class="block block-one"></div>
              <div class="block block-two"></div>
              <div class="block block-three"></div>
              <div class="block block-four"></div>
              <a href="javascript:void(0)">
                <img class="avatar" src="img/mites.jpeg" alt="..." />
                <h5 class="title">Mites</h5>
              </a>
              <p class="description">
                You have consented to participate in Research
              </p>
            </div>
            <p></p>
            <p class="card-description text-center">
              By using this app, you will help us research topics such as
              machine learning with sensor data, privacy preferences regarding
              sensor data, and system performance.
            </p>
            <p class="card-description text-center">
              Participating is completely voluntary, and you can opt-out any
              time you wish!
            </p>
          </card>
        </VueSlickCarousel>
        <!--          </div>-->
      </card>
    </modal>
  </base-nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { BaseNav, Modal } from "@/components";
import SidebarToggleButton from "./SidebarToggleButton";
// import { useBluetooth } from '@vueuse/core'

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import apis from "@/javascripts/buildingdepot";
import { notifyVue } from "@/javascripts/helper";

export default {
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal,
    VueSlickCarousel,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      OnboardModalVisible: false,
      userEmail: "",
    };
  },
  methods: {
    notifyVue: notifyVue,
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$cookies.remove("user");
      this.$cookies.remove("session");
      this.$router.replace({ name: "Login" });
    },
  },
  async mounted() {
    // Making the page accessible only trough login
    let cookieObj = await apis.oAuth.readCookieAPI("user", this.$cookies);
    console.log("User Details Cookie: ", cookieObj);

    // Check login condition
    if (!cookieObj.success) {
      console.log("#Nav bar: No user info. Please login!");
      if (
        cookieObj.error ===
        "Oops! Your session has expired. Please log in again to continue."
      ) {
        this.notifyVue("top", "center", "danger", cookieObj.error, "error");
        this.$cookies.remove("user");
      }
      await this.$router.push({ name: "Login" }).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
    } else {
      console.log("Cookie Available");
      this.userEmail = cookieObj.data.email;
      if (cookieObj.data.bd_access_token === "undefined") {
        this.notifyVue("top", "center", "danger", cookieObj.error, "error");
        this.$cookies.remove("user");
        // Redirect to the login page if not authenticated
        await this.$router.push({ name: "Login" }).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err;
          }
        });
      }
    }
  },
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
