import axios from "axios";

import { bdHostname, bdCSPort } from "@/settings.js";

function getAccessTokenFromClient(clientId, clientKey) {
  if (clientId !== "" && clientKey !== "") {
    console.log("Login Requested");

    return Promise.resolve(
      axios
        .get(`${bdHostname}:${bdCSPort}/oauth/access_token/client_id=${clientId}/client_secret=${clientKey}`)
        .then(async (response) => {
          return Promise.resolve(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return Promise.resolve({
              success: "False",
              error: error.response.data,
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("Error", error.request, error.message);
            return Promise.resolve({
              success: "False",
              error: error.message,
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            return Promise.resolve({
              success: "False",
              error: error.message,
            });
          }
        })
    );
  } else {
    console.log("A client ID and client key must be present");
  }
}

export { getAccessTokenFromClient };
